<template>
    <div>
        <div class="detail">
            <div class="detail-title">
                <img class="moneydetail" src="../assets/deposit/moneydetail.png" alt="">
                <span>
                    账户明细
                </span>
            </div>
<!--            <div style="display: flex">-->
<!--                <tbody style="margin: 0 auto">-->
<!--                    <tr>-->
<!--                        <th class="tablerow2">时间</th>-->
<!--                        <th class="tablerow2">内容</th>-->
<!--                        <th class="tablerow2">金额动态</th>-->
<!--                        <th class="tablerow2">余额</th>-->
<!--                    </tr>-->
<!--                    <tr v-for="(item,inndex) in detailsList">-->
<!--                        <th class="tablerow1">{{item.create_time | dateFormat}}</th>-->
<!--                        <th class="tablerow1">{{item.info}}</th>-->
<!--                        <th class="tablerow1">-->
<!--                           <p v-if="item.type == 1" style="color: #67c23a">-->
<!--                               +{{item.change_money}}元-->
<!--                           </p>-->
<!--                            <p v-if="item.type == 2" style="color:#f56c6c">-->
<!--                                -{{item.change_money}}元-->
<!--                            </p>-->

<!--                        </th>-->
<!--                        <th class="tablerow1">{{item.after_money}}元</th>-->
<!--                    </tr>-->
<!--                </tbody>-->
<!--            </div>-->
            <div>
                <div class="disCenter justify-center colorFF font18 table-tr">
                    <div class="flex1 textCenter">
                        时间
                    </div>
                    <div class="flex1 textCenter">
                        内容
                    </div>
                    <div class="flex1 textCenter">
                        金额动态
                    </div>
                    <div class="flex1 textCenter">
                        余额
                    </div>
                </div>
                <div class="disCenter item font14 " v-for="(item,index) in detailsList">
                    <div class="flex1 color46 textCenter">
                        {{item.create_time | dateFormat}}
                    </div>
                    <div class="flex1 color46 textCenter">
                        {{item.info}}
                    </div>
                    <div class="flex1 color0D9 textCenter">
                        +{{item.change_money}}
                    </div>
                    <div class="flex1 textCenter">
                        {{item.after_money}}
                    </div>
                </div>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 20px">
                <el-pagination
                        class='videoPage'
                        background
                        layout="prev, pager, next"
                        :total="videoTotal"
                        @current-change="sizeChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "detail",
        data(){
            return{
                videoTotal:0,
                page:1,
                detailsList:[],
            }
        },
        created() {
          this.detailListFn()
        },
        methods:{
            detailListFn(){
              this.$get({
                  url:api.money_details,
                  data:{
                      type:'wallet',
                      page:this.page,
                  }
              }).then((res)=>{
                  this.detailsList = res.data.list.data
                    this.videoTotal = res.data.list.total
              })
            },
            sizeChange(e){
                this.page = e
                this.detailListFn()
            }
        }
    }
</script>

<style scoped lang="scss">
    .detail{
        background-color: #fff;
        padding: 20px;
        .detail-title{
            font-size: 15px;
            margin-bottom: 18px;
            font-weight: bold;
            color: #5C5C5C;
            display: flex;
            align-items: flex-end;
            .moneydetail{
                width: 26px;
                height: 28px;
                margin-right: 5px;
            }
        }
        .tablerow2{
            word-break: keep-all;
            white-space: nowrap;
            /*color: #fff;*/
            text-align: center;
            min-width: 120px;
        }
        .tablerow1, .tablerow2 {
            border: 1px solid #fff;
            line-height: 23px;
            padding-right: 5px;
            padding-left: 5px;
        }
        .table-tr{
            height: 53px;
            background:#0D96FF;
        }
        .item{
            height: 46px;
            background-color: #F2F9FF;
            border-bottom: 1px dashed #95D1FF;
        }
        .tablerow1{
            font-size: 13px;
            color: #888888;
            font-style: normal;
            font-weight: normal;
            line-height: 15px;
        }
    }
</style>
