<template>
    <div>
        <div class="login-box">
            <div>
                    <div class="login-form">
                       <div class="login-view">
                           <h4>账号登录</h4>
                           <input type="text" class="elInput"  v-model="account" placeholder="请输入账号">
                           <input type="password" class="elInput" v-model="password" placeholder="请输入密码">
                           <button type="submit" class="login-btn" @click="openLoginFn">登录</button>
                       </div>
<!--                    <div style="margin-top:20px">-->
<!--                      <p>去注册</p>-->
<!--                    </div>-->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "login",
        data(){
            return{
                account:null,
                password:null,
            }
        },
        created() {
          if(localStorage.getItem('token')){
             this.$router.replace('/user')
          }
        },
        methods:{
            openLoginFn(){
                if(!this.account || !this.password){
                    this.$message({
                        message: '请输入账号和密码',
                        type:'error'
                    })
                    return
                }
                this.$post({
                    url:api.login,
                    data:{
                        username:this.account,
                        password: this.password
                    }
                }).then((res)=>{
                    if(res.data.code == 0){
                        localStorage.setItem("token",res.data.token)
                        this.$router.replace('/user')
                    }
                })

            }
        }
    }
</script>

<style  lang="scss">
    .login-form {
        width: 394px;
        height: 394px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        box-sizing: border-box;
        border-radius: 25px;
        background: url("../assets/login-border.png") no-repeat;
        background-size:cover;
        padding: 2px;
    }
    .login-box{
        display: flex;
        align-items: center;
        height: 100vh;
        background: url("../assets/login-bg.jpg") no-repeat;
        background-size: 100vw 100vh;
        /*box-sizing: border-box;*/
        /*padding: 8px;*/
        background-position: 0 0;
        @media screen and (max-width: 1200px) {
            background-position: 0 0;
        }
    }
    input{
        outline: none;
    }
    .login-view{
        width: 100%;
        height: 100%;
        background-color: rgba(0,38,125,0.51);
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 49px 0 39px 0;
        box-sizing: border-box;
        h4{
         font-size: 32px;
            color: white;
            font-family: Microsoft YaHei;
            font-weight: bold;
        }
    }
    .el-input__inner{
        background-color: rgba(0,0,0,.2)!important;
        color: white!important;
    }
     .login-form .elInput {
        width: 318px;
        height: 48px;
        border-radius: 3px;
        /*border: 1px solid rgba(255, 255, 255, 0.5);*/
        background-color:transparent;
        margin-bottom: 24px;
         border: 2px solid #12B9FF;
         border-radius: 25px;
        color: white;
         padding-left: 23px;
    }
    input::-webkit-input-placeholder {
        color: #1AC1FF;
        font-size: 16px;
    }
    .login-btn{
        color: white;
        cursor: pointer;
        width: 318px;
        height: 56px;
        background: linear-gradient(104deg, #0072FF, #FC611E);
        border-radius: 15px;
        font-size: 24px;
    }
    .p-img{
        width: 460px;
        height: 86px;
        margin-left: 130px;
    }

</style>
