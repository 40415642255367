<template>
    <div>
        <div class="content-box">
          <div class="bgff padd-title">
              <div class="box-title flex-end justify-between">
                  <div class="flex-end">
                      <img class="title-icon" src="../assets/missin.png">
                      <span class="title">
                         任务
                      </span>
                  </div>
                  <div class="nav-text">
                      接手限制:【<span class="nav-span">{{getUserList.day_accept_nums}}</span>】个&nbsp;&nbsp;本日已接:【<span class="nav-span">{{todeyList}}</span>】个&nbsp;&nbsp;剩余:【<span class="nav-span">{{getUserList.day_accept_nums - todeyList}}</span>】个
                  </div>
              </div>
              <div class="box-nav">
                  <div class="nav-btn">
                      <div class="refresh-btn"
                           :class="index === refreshIndex?'refresh-action':''"
                           v-for="(item,index) in btnList"
                           @click="refreshFn(index)"
                      >
                          <img :src="item.icon">
                          <span>
                            {{item.text}}
                        </span>
                      </div>
                  </div>

              </div>
          </div>
            <table class="tableborder" cellspacing="1" cellpadding="3" border="0" width="1109">
                <div>
<!--                <tr>-->
<!--                    <th class="tablerow2"><b>商品图片</b></th>-->
<!--                    <th class="tablerow2"><b>商品标题</b></th>-->
<!--                    <th class="tablerow2"><b>链接</b></th>-->
<!--                    <th class="tablerow2"><b>任务信息</b></th>-->
<!--                    <th class="tablerow2"><b>任务要求</b></th>-->
<!--                     <th class="tablerow2"><b>接手时间</b></th>-->
<!--                    <th class="tablerow2"><b>状态</b></th>-->

<!--                    <th class="tablerow2"><b>操作</b></th>-->
<!--                </tr>-->
                <div class="taskItem" style="text-align:center;background-color:#fff;margin-bottom: 8px" v-for="(item,index) in taskListes">
                    <div class="tablerow-photo">
                        <img :src="url_prefix + item.goods.img" border="0" alt="商品图"/>
                    </div>
                    <div class="itemContent flex1 marLeft11 dis flex-column">
                        <div class="disCenter justify-between  pabottom9 borBottomC9">
                            <div class="disCenter">
                                <div class="target">
                                    {{goodsClassList[item.goods.class_id - 1].long_name}}
                                </div>
                                <span class="color65 font18">
                                    产品名称：{{item.goods.title}}
                                </span>
                            </div>
                            <div class="status" :class="item.status == 5 ? 'bgBA' : 'bgFFAA'">
                                <p v-if="item.status == 1">未发货</p>
                                <p v-if="item.status == 2">待上传</p>
                                <p v-if="item.status == 3">待审核</p>
                                <p v-if="item.status == 4">已分发</p>
                                <p v-if="item.status == 5">已完成</p>
                            </div>
                        </div>
                            <div class="dis justify-between flex-column flex1 line-height33">
                            <div class="disCenter">
                            <span class="font15 color78">
                                产品ID：{{item.id}}
                            </span>
                            </div>
                            <div class="disCenter font15 color78">
                                任务数量: {{item.nums}}
                            </div>
                            <div class="disCenter justify-between ">
                                <div class="disCenter">
                                    <p class="font15 color78">
                                        接手时间：{{item.pk_js_time | dateFormat}}
                                    </p>
                                    <div class="disCenter detail">
                                        <img src="../assets/mission/detail.png" alt="">
                                        <span>
                                            <a :href="item.goods.link" target="_blank" >产品详情</a>

                                    </span>
                                    </div>
                                    <div class="disCenter spot" @click="dialogUpFn(item)">
                                        <img src="../assets/mission/spot.png" alt="">
                                        <span>
                                        要求亮点
                                        </span>
                                    </div>
                                    <div class="upload disCenter justify-center" v-if="item.status == 2" @click="newupvideo(item.id,item)">
                                        上传视频
                                    </div>
                                    <div class="lookKd disCenter justify-center" v-if="item.status == 2" @click="checkSite(item)">
                                       <span>查看快递</span>
                                    </div>
                                    <div class="upload disCenter justify-center" v-if="item.status == 3" @click="newupvideo(item.id,item)">
                                        查看审核
                                    </div>
                                    <div class="lookKd disCenter justify-center" v-if="item.status == 3"@click="repulseFn(item.id)">
                                        打回视频
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="tablerow1" style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">-->
<!--                        <p>{{item.goods.title}}</p>-->
<!--                        分类:{{goodsClassList[item.goods.class_id - 1].long_name}}-->
<!--                    </div>-->
<!--                    <div class="tablerow1" height="1" width="150" align="center"><a-->
<!--                            :href="item.goods.link" target="_blank"-->
<!--                            class="uibtn_blue">查看链接</a>-->
<!--                    </div>-->

<!--                    <div class="tablerow1"  style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">-->

<!--                        <p>ID: {{item.id}}</p>-->
<!--                        任务数量:{{item.nums}}-->
<!--                    </div>-->
<!--                    <div class="tablerow1" height="1" width="224" align="center">-->
<!--                        <p-->
<!--                           class="open-box uibtn_blue"-->
<!--                           @click="dialogUpFn(item)"-->
<!--                           >-->
<!--                            查看亮点和要求-->
<!--                        </p>-->
<!--                    </div>-->
<!--                     <div class="tablerow1" height="1" width="224" align="center">-->
<!--                        <p> {{item.pk_js_time | dateFormat}}</p>-->
<!--                    </div>-->
<!--                    <div class="tablerow1" style="color:rgb(144, 147, 153)" height="" width="145" align="center">-->
<!--                       <p v-if="item.status == 1">未发货</p>-->
<!--                        <p v-if="item.status == 2">待上传</p>-->
<!--                        <p v-if="item.status == 3">待审核</p>-->
<!--                        <p v-if="item.status == 4">已分发</p>-->
<!--                        <p v-if="item.status == 5">已完成</p>-->
<!--                    </div>-->

                </div>
                </div>
            </table>
            <el-pagination
                class='videoPage'
                background
                layout="prev, pager, next"
                :total="videoTotal"
                @current-change="sizeChange"
                >
            </el-pagination>

        </div>
    <!--商家要求亮点提示-->
<!--        <el-dialog-->
<!--                title="提示"-->
<!--                :visible.sync="centerDialogVisible"-->
<!--                width="30%"-->
<!--                center>-->
<!--            <p class="btn">宝贝亮点</p><div style="padding:10px;font-size:14px;text-align:center;">{{liangdian}}</div>-->
<!--            <p class="btn">宝贝要求</p><div style="padding:10px;font-size:14px;text-align:center;">{{pk_yaoqiu}}</div>-->
<!--        </el-dialog>-->
        <el-dialog
                title=""
                :visible.sync="centerExpressVisible"
                width="423px"
                height="462px"
                :show-close="false"
                center>
<!--            <p class="btn">快递名</p>-->
<!--            <p style="text-align: center">{{itemObj.express_name}}</p>-->
<!--            <p class="btn">快递单号</p>-->
<!--            <p style="text-align: center">{{itemObj.express_number}}</p>-->
        </el-dialog>
        <div class="popup" @mousewheel="mousewheel" v-if="centerDialogVisible">
           <div class="view">
               <div class="popup-view">
                   <div class="fixed_title">提示</div>
                   <div class="yq">
                       <div class="yqTitle">
                           {{type == 'kd' ? '快递名' : '宝贝要求'}}
                       </div>
                       <div class="yqInput">
                           {{type == 'kd' ? itemObj.express_name :pk_yaoqiu}}
                       </div>
                   </div>
                   <div class="ld">
                       <div class="ldTitle">
                           {{type == 'kd'? '快递单号' : '宝贝亮点'}}
                       </div>
                       <div class="ldInput">
                           {{type == 'kd' ? itemObj.express_number :  liangdian}}
                       </div>
                   </div>
               </div>
               <img class="close" @click="centerDialogVisible = false" src="../assets/close.png" alt="">
           </div>

        </div>
        <el-dialog
                title="提示"
                :visible.sync="repulseShow"
                width="20%"
                :before-close="beforeFn">
            <span>确定打回</span>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="repulseShow = false">取 消</el-button>
                    <el-button type="primary" @click="repulseCanFn">确定</el-button>
                  </span>
        </el-dialog>

    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "mission",
        data(){
            return{
                getUserList:{
                    day_accept_nums:0,
                },
                type:'',
                todeyList:null,
                itemObj:{},
                refreshIndex:0,
                videoTotal:0,
                pk_yaoqiu:null,//商品要求
                liangdian:null,//亮点
                taskListes:null,
                goodsClassList:[
                    {
                        long_name:null,
                    }
                ],
                centerDialogVisible:false,
                centerExpressVisible:false,
                page: 1,
                btnList:[
                    {
                        text:'全部任务',
                        icon:require('../assets/mission/missionIcon1.png')
                    },
                    {
                        text:'未发货',
                        icon:require('../assets/mission/missionIcon2.png')
                    },
                    {
                        text:'待上传',
                        icon:require('../assets/mission/missionIcon3.png')
                    },
                    {
                        text:'待审核',
                        icon:require('../assets/mission/missionIcon4.png')
                    },
                    {
                        text:'已分发',
                        icon:require('../assets/mission/missionIcon5.png')
                    },
                    {
                        text:'已完成',
                        icon:require('../assets/mission/missionIcon6.png')
                    }
                ],
                url_prefix:null,
                repulseShow:false,
                repulseId:null,
            }
        },
        created() {
          this.myTaskListFn()
            this.getClassFn()
            this.url_prefix = api.url_prefix
            this.todeyAcceptFn()
        },
        methods:{
            todeyAcceptFn(){
                this.$get({
                    url:api.get_user
                }).then((res)=>{
                    this.getUserList = res.data.user
                })
                this.$get({
                    url:api.today_accept
                }).then((res)=>{
                    this.todeyList = res.data.nums
                })
            },
            mousewheel(e){
                e.preventDefault();
            },
            getClassFn(){
                this.$get({
                    url:api.get_goods_class,
                }).then((res)=>{
                    this.goodsClassList = res.data.list
                })
            },
            myTaskListFn(){
                this.$get({
                    url:api.pk_my_task_list,
                    data:{
                        page:this.page,
                        status:this.refreshIndex==0?null:this.refreshIndex,
                    }
                }).then((res)=>{
                    this.taskListes = res.data.list.data
                    this.videoTotal = res.data.list.total
                })
            },
            sizeChange(e){
                this.page = e
                this.myTaskListFn()
            },
            dialogUpFn(item){
                this.type = 'ld'
                this.centerDialogVisible = true
                this.pk_yaoqiu = item.pk_yaoqiu
                this.liangdian = item.liangdian
            },
            newupvideo(id,item){
                if(item.status == 2){
                    this.$router.push({path:'/loading',query: {id:id,status:item.status}})
                    console.log(item)
                }else if(item.status == 3){
                    this.$router.push({path:'/check',query: {id:id,status:item.status}})
                    console.log(item)
                }
            },
            repulseCanFn(){
                let data = {
                    id:this.repulseId
                }
                this.$post({
                    url:api.pk_re_up_task,
                    data:data
                }).then((res)=>{
                    if(res.data.code == 0){
                        this.$message({
                            message:res.data.msg,
                            type:'success'
                        })
                    }else if(res.data.code == 1){
                        this.$message({
                            message:res.data.msg,
                            type:'error'
                        })
                    }
                    this.repulseShow = false
                    this.page = 1
                    this.myTaskListFn()

                })
            },
            beforeFn(){
              this.repulseShow = false
            },
            repulseFn(id){//打回视频
                this.repulseShow = true
                this.repulseId = id
            },
            refreshFn(index){
                    this.refreshIndex = index
                    this.myTaskListFn()
            },
            checkSite(item){
                this.type = 'kd'
                this.centerDialogVisible = true
                this.itemObj = item
            }
        }
    }
</script>

<style scoped lang="scss">
    .taskItem{
        display: flex;
        padding: 22px 14px 21px 7px;
        box-sizing: border-box;
    }

    .target{
        padding: 2px 9px;
        background-color: #3EB9FF;
        border-radius: 9px;
        color: white;
        margin-right: 4px;
    }
    .popup{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        .close{
            width: 46px;
            height: 46px;
            margin-top: 31px;
        }
        .view{
            width: 460px;
            display: flex;
            align-items: center;
            flex-direction: column;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

        }
        .popup-view{
            width: 460px;
            height: 502px;
            background: url("../assets/popup/popup-bg.png") no-repeat;
            background-size: 460px 502px;
            .fixed_title{
                font-size: 24px;
                color: white;
                font-weight: bold;
                position: absolute;
                left: 50%;
                top: 15px;
                transform: translateX(-50%);
            }
            .ld{
                position: relative;
                width: 356px;
                margin: 58px auto 0;
                .ldTitle{
                    position: absolute;
                    left: 0;
                    top: -37px;
                    width: 91px;
                    height: 37px;
                    background: #FFA21B;
                    border-radius: 15px 15px 0px 0px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                }
                .ldInput{
                    width: 100%;
                    height: 95px;
                    background: #F4F4F4;
                    border: 1px solid #DFDFDF;
                    border-radius: 0px 15px 15px 15px;
                    padding: 10px;
                    box-sizing: border-box;
                    color: #444444;
                }
            }
            .yq{
                position: relative;
                width: 356px;
                margin: 118px auto 0;
                .yqTitle{
                    position: absolute;
                    left: 0;
                    top: -37px;
                    width: 91px;
                    height: 37px;
                    background: #178FFF;
                    border-radius: 15px 15px 0px 0px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                }
                .yqInput{
                    width: 100%;
                    height: 95px;
                    background: #F4F4F4;
                    border: 1px solid #DFDFDF;
                    border-radius: 0px 15px 15px 15px;
                    padding: 10px;
                    box-sizing: border-box;
                }
            }
        }
    }
    .pabottom9{
        padding-bottom: 9px;
    }
    .bgFFAA{
        background-color: #FFAA30;
    }
    .bgBA{
        background-color: #BABABA;
    }
    .status{
        padding: 7px 16px;
        border-radius: 16px;
        color:white;
        font-size: 13px;
        line-height: 13px;
    }
    .detail{
        padding: 4px 13px;
        border: 2px solid #3FC6F8;
        border-radius: 17px;

        margin-left: 23px;
        font-size: 14px;
        line-height: 14px;
        a{
            color: #3FC6F8;
        }
        img{
            width: 19px;
            height: 20px;
            margin-right: 5px;
        }
    }
    .spot{
        padding: 2px 13px;
        border: 2px solid #FFAA30;
        border-radius: 17px;
        color: #FFAA30;
        margin-left: 12px;
        font-size: 14px;
        line-height: 14px;
        box-sizing: border-box;
        img{
            width: 19px;
            height: 25px;
            margin-right: 5px;
        }
    }
    .upload{
        padding: 9px 21px;
        background-color: #007EED;
        color: white;
        font-size: 14px;
        line-height: 14px;
        border-radius: 17px;
        margin-left: 13px;
        cursor: pointer;
    }
    .lookKd{
        padding: 9px 21px;
        background-color: #3EB9FF;
        color: white;
        font-size: 14px;
        margin-left: 13px;
        line-height: 14px;
        border-radius: 17px;
    }
    a{
        text-decoration: none;
    }
    .flex1{
        flex: 1;
    }
    .disCenter{
        display: flex;
        align-items: center;
    }
    .tablerow-photo{
        width: 119px;
        height: 119px;
        img{
            width: 119px;
            height: 119px;
            border-radius: 20px;
        }
    }
    .title-icon{
        width: 24px;
        height: 28px;
        margin-right: 4px;
    }
    .nav-text{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #6B6B6B;
    }
    .nav-span{
        font-weight: 400;
    }
    .title{
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 22px;
        color: #FFAA30;
    }
    .justify-between{
        justify-content: space-between;
    }
    .bgff{
        background-color: #fff;
    }
    .padd-title{
        padding: 11px 10px 14px;

    }
    .content-box {
        width: 100%;
        .box-title {
            text-align: left;
            font-size: 13px;
            margin-bottom: 10px;
            background-color: #fff;
        }

        .box-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            margin: 0 -7px;
            .nav-btn {
                display: flex;
                flex: 1;
                align-items: center;
                background-color: #fff;
                .refresh-btn{
                    position: relative;
                    flex: 1;
                    color: red;
                    border: 0px;
                    padding: 14px 0px;
                    margin-right: 3px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    border-radius: 3px;
                    white-space: nowrap;
                    color: white;
                    background-color: #BBBBBB;
                    cursor: pointer;
                    font-size: 18px;
                    img{
                        width: 29px;
                        height: 29px;
                        margin-bottom: 10px;
                    }
                }
                .refresh-action{
                    background-color: #FFAA30;
                    cursor: pointer;
                    position: relative;
                    border: 0px;
                    flex: 1;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    border-radius: 3px;
                    white-space: nowrap;
                    color: white;
                }
            }
        }
    }


    .tableborder {
        border: 0px #8fb9d0 solid;
        border-radius: 2px;
        width: 100%;
        margin-top: 0;
        margin-bottom: 5px;
        magin-right: 20px;
        font-size: 13px;
        margin-top: 20px;
    }

    .tableborder th, .minwidth {
        word-break: keep-all;
        white-space: nowrap;
        color: rgb(144, 147, 153);
        text-align: center;
        font-style: normal;
        font-weight: normal;
    }

    .uibtn_blue {
        display: inline-block;
        background: #0099FF;
        color: #fff;
        padding: 4px 8px;
        border-radius: 99px;
        font-size: 12px;
        white-space: nowrap;
        cursor: pointer;
    }
    .elbtn{
        font-size: 12px;
        padding: 5px 10px;
    }
    .videoPage{
        text-align:center;
    }
     .btn {
        position: relative;
        color: #fff;
        border: 0px;
        background:#0099FF;
        padding: 4px 8px;
        margin-left: 10px;
        border-radius: 3px;
        word-break: keep-all;
        white-space: nowrap;
        text-align:center;
        transition-duration: .8s;
    }
</style>
