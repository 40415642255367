<template>
    <div>
        <div class="loading">
<!--            <div class="loading-title">-->
<!--                <span>-->
<!--                    上传视频-->
<!--                </span>-->
<!--            </div>-->
            <div style="display: flex;">
                <div>
                    <div class="marBottom16">
                        <span class="font-bold color33 font18">
                            拖拽上传
                        </span>
                        <span class="font14 color76">
                            将需要上传的视频拖拽到下面方框中
                        </span>
                    </div>
                    <el-form>
                        <el-form-item label="">
                            <el-upload
                                    :file-list="fileListArr"
                                    :action="action"
                                    accept=".png,.mp4"
                                    :on-exceed="handleExceedFj"
                                    :on-remove="handleRemoveFj"
                                    :before-upload="beforeUpload"
                                    ref="scfj"
                                    :show-file-list="false"
                                    multiple
                                    :limit="taskDetails.nums"
                                    drag
                                    :auto-upload="false"
                                    :on-change="onChangeFj"
                                    :http-request="uploadFile"
                            >
                                <img class="uploadIcon" src="../assets/uploadIcon.png">
                                <p class="font16">
                                    <span class="color2CBC">
                                        点击上传文件
                                    </span>
                                    <span class="color33">
                                        获取拖拽上传
                                    </span>
                                </p>
                            </el-upload>
                            <div class="marTop14 disCenter justify-center">
                                <el-button
                                        style="margin-left: 10px;"
                                        size="small"
                                        type="success"
                                        @click="uploadFj">上传视频
                                </el-button>
                                <el-button
                                        size="small"
                                        @click="saveVideoFn"
                                >
                                    保存视频
                                </el-button>
                                <el-button
                                        size="small"
                                        @click="refreshVideoFn"
                                >
                                    刷新重传
                                </el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div>
                    <div class="goodsImg">
                        <img  :src="preFix + taskDetails.goods.img" alt="" >
                    </div>
                    <div class="title color33">
                        <div><div class="line"></div> 商品名称：{{taskDetails.goods.title}}</div>
                        <div><div class="line"></div> 视频数量：{{taskDetails.nums}}</div>
                        <div><div class="line"></div> 商品价格：{{taskDetails.goods.price}}元</div>
                    </div>
                </div>
<!--                <div style="height: 200px;">-->
<!--                    <div class="fileList">-->
<!--                        <tr class="fileList-tr" v-if="fileListArr.length">-->
<!--                            <th>-->
<!--                                <p>类名</p>-->
<!--                            </th>-->
<!--                            <th>-->
<!--                                <p>大小</p>-->
<!--                            </th>-->
<!--                            <th>-->
<!--                                <p>状态</p>-->
<!--                            </th>-->
<!--                            <th>-->
<!--                                <p>重新上传</p>-->
<!--                            </th>-->
<!--                        </tr>-->
<!--                        <tr class="fileList-tr" v-for="(item,index) in fileListArr">-->
<!--                            <td>{{item.name}}</td>-->
<!--                            <td>{{(item.size/1024/1024).toFixed(1)}}MB</td>-->
<!--                            <td :class="item.status == '上传成功'?'winColor':''">{{item.status}}</td>-->
<!--                            <td style="display: flex;align-items: center">-->
<!--                                <el-upload-->
<!--                                        action="https://gz.zxw233.com/api/common/upload_file"-->
<!--                                        :show-file-list="false"-->
<!--                                        :file-list="fileList"-->
<!--                                        :auto-upload="false"-->
    <!--                                        :on-change="onChangeFn"-->
<!--                                >-->
<!--                                    <el-button size="small" type="primary" @click="onProgress(index)">点击上传</el-button>-->
<!--                                </el-upload>-->
<!--                                <el-button class="elBtn" slot="trigger" size="small" type="danger" @click="again(index)"-->
<!--                                           v-if="index == 1">重新上传-->
<!--                                </el-button>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
<!--            <el-main>-->
<!--                <el-table :data="fileListArr"  max-height="500">-->
<!--                    <el-table-column prop="name" :label="'文件名(' +fileListArr.length+')'" width="300">-->
<!--                    </el-table-column>-->
<!--                    <el-table-column prop="size" label="大小" width="200">-->
<!--                        <template slot-scope="scope">-->
<!--                            <div>-->
<!--                                <p>{{(scope.row.size/1024/1024).toFixed(1)}}MB</p>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column prop="status" label="状态" width="200">-->
<!--                        <template slot-scope="scope">-->
<!--                            <div>-->
<!--                                <p :class="scope.row.status == '上传成功'?'winColor':''">{{scope.row.status}}</p>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column prop="address" label="操作" width="200">-->
<!--                        <template slot-scope="scope">-->
<!--                            <div style="display: flex" v-if="taskStatus == 3">-->
<!--                                <el-upload-->
<!--                                        :action="action"-->
<!--                                        :show-file-list="false"-->
<!--                                        :file-list="fileList"-->
<!--                                        accept=".png,.mp4,.jpg"-->
<!--                                        :auto-upload="false"-->
<!--                                        :on-change="onChangeFn"-->
<!--                                >-->
<!--                                    <el-button size="small" type="primary" @click="onProgress(scope.$index)">点击上传</el-button>-->
<!--                                </el-upload>-->
<!--                                <el-button class="elBtn" slot="trigger" size="small" type="danger" @click="again(scope.$index)"-->
<!--                                           v-if="scope.$index == 1">重新上传-->
<!--                                </el-button>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                </el-table>-->
<!--            </el-main>-->
            <div class="">
                <div class="disCenter textCenter tableTr  colorFF">
                    <div class="flex2">
                        文件名
                    </div>
                    <div class="flex1">
                        大小
                    </div>
                    <div class="flex1">
                        状态
                    </div>
                </div>
                <div class="width100 disCenter font14 justify-end height37 borBottom82C paRight13">
                    <span class="colorFF00">
                        *注
                    </span>
                    <span class="color0D9 marLeft10">
                         目前上传数量：{{fileListArr.length}} 个  还差：{{taskDetails.nums - fileListArr.length}}个才能上传视频
                    </span>
                </div>
                <div class="disCenter  item font16" v-for="(item,index) in fileListArr" v-if="(index >= (listPage-1) * 10) && index < listPage * 10 ">
                    <div class="flex2 color46">
                        {{item.name}}
                    </div>
                    <div class="flex1 color46 textCenter">
                        {{(item.size/1024/1024).toFixed(1)}}MB
                    </div>
                    <div class="flex1 textCenter" :class="item.status == '上传成功' ? 'color0D9' : 'colorFFAA'">
                        {{item.status == 'ready' ? '待上传...' : item.status}}
                    </div>
                </div>



                <el-pagination
                        style="display: flex;justify-content: center;margin-top: 20px"
                        background
                        :page-size="10"
                        layout="prev, pager, next"
                        :current-page="listPage"
                        @current-change="sizeChange"
                        :total="fileListArr.length">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {cos} from "../lnit/cos";
    import axios from 'axios'
    import api from '../lnit/api'
    export default {
        name: "loading",
        data() {
            return {
                centerDialogVisible:false,
                formData: null,
                fileListArr: [],
                saveVideoList:[],
                fileList: [],
                fromStatus: 1,
                i:1,
                uploadIndex: null,
                saveStatus:false,//上传成功后保存视频
                taskId:null,//任务id
                taskStatus:null,
                taskDetails: {
                    nums:0,
                },
                listPage:1,
                preFix:null,
                action:'',
            }
        },
        created() {
            this.action = api.url + api.upload_file
            this.taskId = this.$route.query.id
            this.taskStatus = this.$route.query.status
            this.taskDetailsFn()
            this.preFix = api.url_prefix
        },
        methods: {
            taskDetailsFn(){
                this.$get({
                    url:api.pk_task_details,
                    data:{
                        id:this.taskId,
                    }
                }).then((res)=>{
                    this.taskDetails = res.data.info
                })
            },
            uploadFile(file) {
                console.log(file)
                // this.formDate.append('file', file.file)
                // console.log(this.formDate)
            },
            saveVideoFn(){
                if(!this.saveStatus){
                    this.$message({
                        message:'不满足保存条件',
                        type:'error'
                    })
                    return
                }
                this.$post({
                    url:api.save_videos,
                    data:{
                        id:this.taskId,
                        video_src:String(this.saveVideoList)
                    }
                }).then((res)=>{
                    if(res.data.code === 0){
                        this.$message({
                            message:"保存成功",
                            type:'success'
                        })
                        setTimeout(()=>{
                            this.$router.replace('/mission')
                        },1000)

                    }else if(res.data.code === 1){
                        this.$message({
                            message:res.data.msg.msg,
                            type:'warning'
                        })
                    }
                })
            },
            sizeChange(e){
                console.log(e)
                this.listPage = e
            },
            onChangeFn(file, fileList) {
                this.fileListArr[this.uploadIndex] = file
                this.$forceUpdate()
            },
            onProgress(index) {
                this.uploadIndex = index
            },
            beforeUpload() {
                this.fileListArr.forEach((item, i) => {
                    item.status = '待上传'
                })
            },
            handleExceedFj(file, fileList) {

            },
            onChangeFj(file) {
                let currList = this.fileListArr
                for (let i=0;i<currList.length;i++){
                    // console.log(file.name==currList[i].name)
                    if(file.name==currList[i].name && file.size == currList[i].size){
                       this.$message({
                           message:'重复文件已自动过滤',
                           type:'error'
                       })
                        return
                    }
                }
                this.fileListArr.push(file)

            },
            handleRemoveFj() {

            },
            uploadFj() {
                // if(this.fileListArr.length  != this.taskDetails.nums){
                //     this.$message({
                //         message:'只能上传'+this.taskDetails.nums+'个视频',
                //         type:'error'
                //     })
                //     return
                // }
                if(this.saveStatus){
                    this.$message({
                        message:'已上传完成，请保存',
                        type:'error'
                    })
                    return
                }

                if (!this.fromStatus) {
                    this.$message({
                        message:'上传中',
                        type:'error'
                    })
                    return
                }

                if (this.i > this.fileListArr.length) {
                    this.i = 1
                    return
                }
               // this.fileListArr[this.i - 1].status = '上传中...'
                this.fromStatus = 0
                this.$refs.scfj.submit();
                let that = this
                let nowTime = new Date().getTime()
                let filePath = []
                this.fileListArr.map((v,i)=>{
                    let obj = {
                        Bucket: 'jzt-1326505554', /* 填入您自己的存储桶，必须字段 */
                        Region: 'ap-shanghai',  /* 存储桶所在地域，例如 ap-beijing，必须字段 */
                        Key:'video/jzt/video/20240510/' + v.name,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                        Body:v.raw,
                        onTaskReady: function(taskId) {
                            /* taskId 可通过队列操作来取消上传 cos.cancelTask(taskId)、停止上传 cos.pauseTask(taskId)、重新开始上传 cos.restartTask(taskId) */
                            that.fileListArr[i].status = '上传中...'

                        },
                        Headers: {
                            'x-cos-meta-test': 123
                        },
                    }
                    filePath.push(obj)
                })
                cos.uploadFiles({
                    files:filePath,
                    SliceSize: 1024 * 1024 * 5,/* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
                    onProgress: function (progressData) {           /* 非必须 */
                    },
                    onFileFinish: function (err, data, options) {   /* 非必须 */
                        that.saveVideoList.push(options.Key)
                        that.fileListArr[options.Index].status = '上传成功'
                        that.listPage =  Math.ceil(options.Index / 10)
                    },

                }, function(err, data) {
                    that.fromStatus = 1
                    that.$message.success('上传完成')
                    that.saveStatus = true
                    console.log((new Date().getTime() - nowTime) / 1000)

                })


                return


                this.formDate = new FormData()
                // this.formDate.append('file', "12133")
                //let a = this.formDate.get('file')
                if (this.i > this.fileListArr.length) {
                    return
                }
                this.formDate.set('file', this.fileListArr[this.i - 1].raw)
                console.log(this.formDate)
                axios.post(api.url + "/api/common/upload_file", this.formDate, {
                    headers: {
                        "token": localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.saveVideoList.push(res.data.url)
                    this.fromStatus = 1
                    this.fileListArr[this.i - 1].status = '上传成功'
                    if(this.i == this.fileListArr.length){
                        this.saveStatus = true
                        this.$message({
                            message:'上传完成，请保存',
                            type:'success'
                        })
                        return
                    }
                    if(this.i > 10 && this.i < 20){
                        this.listPage = 2
                    }else if (this.i > 20 && this.i < 30){
                        this.listPage = 3
                    }else if(this.i > 30 && this.i < 40){
                        this.listPage = 4
                    }else  if( this.i > 40 && this.i < 50){
                        this.listPage = 5
                    }
                    this.uploadFj(this.i++)

                }).catch(res => {

                })
            },
            again(index) {
                this.fileListArr[index].status = '重新上传'
                this.uploadFj(index, 0)
            },
            refreshVideoFn(){//强制刷新页面
                location.reload()
            }
        }
    }
</script>

<style scoped lang="scss">
    td {
        min-width: 200px;
        text-align: center;
        line-height: 64px;
        height: 64px;
    }
    .uploadIcon{
        width: 99px;
        height: 99px;
        margin-bottom: 20px;
    }
    .item{
        height: 48px;
        border-bottom: 1px solid #D9D9D9;
    }
    th {
        min-width: 200px;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }

    td:nth-child(1) {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .tableTr{
        height: 53px;
        background-color: #0D96FF;
    }
    .loading {
        padding:10px 20px 20px;
        background-color: #fff;
        .loading-title {
            margin-bottom: 35px;
        }
    }
    .el-upload-list .el-upload-list--text {
        border: 2px solid;
    }

    .fileList {
        width: 100%;

        .fileList-tr {
            width: 100%;
        }
    }

    .fileList-btn {
        display: inline-block;
        padding: 0 15px;
        background-color: red;
        font-size: 12px;
        color: white;
        cursor: pointer;
        border-radius: 22px;
        height: 30px;
        line-height: 30px;
        margin: 0;
    }

    .fileList-btns {
        margin-right: 10px;
    }

    .winColor {
        color: #67c23a;
    }

    .elBtn {
        height: 32px;
        margin-left: 10px;
    }
    .title{
        margin-left: 40px;
        margin-top: 18px;
        font-size: 16px;
        line-height: 16px;
        div{
            margin-bottom: 14px;
            display: flex;
            align-items: center;
        }
    }
    .line{
        width: 12px;
        height: 12px;
        background-color: #BBBBBB;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 0!important;
    }
    .goodsImg{
        width: 149px;
        height: 149px;
        /*border-radius: 50%;*/
        margin-top:40px;
        margin-left: 40px;
        img{
            width: 100%;
            height: 100%;
            /*border-radius: 50%;*/
        }
    }
</style>
