<template>
    <div>
        <div class="content-header">
           <div class="user">
               <img class="uheadimg"
                    :src="userList.avatar?url_prefix + userList.avatar:'https://cdn.ssjzt.com/images/jzt/avatar/20240530/2024053011144521756.png?imageView2/3/w/50/h/50'"
                    alt="">
               <div class="user-account">
                   <div class="disCenter justify-between">
                       <div class="disCenter ">
                           <img class="userIcon" src="../assets/icon/userIcon.png">
                           <div><span class="name">账号：</span> <span class="span">{{userList.username}}</span></div>
                           <p class="time-invitation"><span class="name">邀请码：</span><span class="span">{{userList.yqm}}</span></p>
                       </div>
                       <!--<img class="user-class-two" src="../newweb/img/ffdr.png" alt="">-->
                       <p class="disCenter"><span class="text name">余额：</span><span class="price">{{userList.wallet}}</span></p>
                   </div>
                   <div class="handle justify-between">
                       <div class="disCenter">
                           <img class="userIcon" src="../assets/icon/timeIcon.png">
                           <div class="time">
                               <p><span class="name">入驻时间：</span><span class="span">{{userList.create_time | dateFormat}}</span></p>
                           </div>
                           <div class="time" style="margin-left: 20px">
                               <p><span class="name">单个视频佣金：</span><span class="span">{{userList.one_video_price }}</span></p>
                           </div>
                       </div>
                       <div style="display: flex;align-items: center">
                          <button class="btn-details deposit" @click="$router.push('/deposit')">提现</button>
                          <button class="btn-details detail" @click="$router.push('/detail')">明细</button>
                       </div>
                   </div>
               </div>



           </div>
       </div>
<!--        <div class="tab">-->
<!--            <div class="tab-title">-->
<!--                <span class="title-p">出单情况</span>-->
<!--                <button class="tab-btn">刷新数据</button>-->
<!--            </div>-->
<!--            <div class="tab-main">-->
<!--                <table>-->
<!--                    <thead>-->
<!--                        <tr class="thead">-->
<!--                            <th class="tab-th">达人ID</th>-->
<!--                            <th class="tab-th">宝贝标题</th>-->
<!--                            <th class="tab-th">付款金额</th>-->
<!--                            <th class="tab-th">付款时间</th>-->
<!--&lt;!&ndash;                            <th class="tab-th">带货视频</th>&ndash;&gt;-->
<!--                            <th class="tab-th">订单状态</th>-->
<!--                        </tr>-->
<!--                        <tr class="thead-data" v-for="(item,index) in 10">-->
<!--                            <th class="tab-th-num">pk01</th>-->
<!--                            <th class="tab-th-num">飞机</th>-->
<!--                            <th class="tab-th-num">100</th>-->
<!--                            <th class="tab-th-num">2021-08-07</th>-->
<!--                            &lt;!&ndash;                            <th class="tab-th">带货视频</th>&ndash;&gt;-->
<!--                            <th class="tab-th-num">发货中</th>-->
<!--                        </tr>-->
<!--                    </thead>-->
<!--                </table>-->

<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    import api from '../lnit/api'
    import unit from '../lnit/filter'
    export default {
        name: "user",
        data(){
            return{
                userList:{},
                url_prefix:null,
                photo:'',
            }
        },
        created() {
            this.getUserFn()
            this.url_prefix = api.url_prefix
        },
        methods:{
            getUserFn(){
                this.$post({
                    url:api.get_user,
                }).then((res)=>{
                    this.userList = res.data.user
                })
            }
        }
    }
</script>

<style scoped lang="scss">
     .content-header  {
        display: flex;
        font-size: 15px;
        color: #222;
        align-items: center;
         background-color: #ffffff;
    }
     .disCenter{
         display: flex;
         align-items: center;
     }
     .user-account{
         flex: 1;
     }
     .time-invitation{
         margin-left: 48px;
     }
     .text{
         font-size: 24px;

     }
     .price{
         font-size: 30px;
         color: #FFA708;
         font-weight: bold;
     }
     .userIcon{
         width: 29px;
         height: 29px;
         margin-right: 14px;
     }
     .justify-between{
         justify-content: space-between;
     }
     .name{
        color: #414040;
     }
     .span{
         color: #797979;
     }
    .user{
        display: flex;
        font-size: 15px;
        color: #222;
        padding: 42px 70px;
        align-items: center;
        flex: 1;
        .uheadimg{
            width: 88px;
            height: 88px;
            border: 4px solid #007EED;
            border-radius: 50%;
        }
    }
    .handle{
        display: flex;
        align-items: center;
        margin-top: 19px;
        .btn-details{
            display: inline-block;
            width: 68px;
            height: 30px;
            font-size: 11px;
            border-radius: 500px;
            outline: none;
            margin-left: 10px;
            cursor:pointer;
        }
    }
    .deposit{
        background: #FFA708;
        color: white;
    }
    .detail{
        border: 2px solid #5CA2FF;
        color: #5CA2FF;
        background-color: #fff;
    }
    .time{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .tab{
        margin-top: 30px;
        padding: 20px;
        background-color: #fff;
        .tab-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #222222;
            font-size: 14px;
            border-left: 3px solid #555;
            padding-left:8px ;
            .title-p{
                margin: 0;
                font-width: 100;
            }
            .tab-btn{
                width: 80px;
                height: 24px;
                background-color: #4C94FF;
                border-radius: 2000px;
                color: #fff;
                font-size: 12px;
                border: none;
                outline: none;
            }
        }
        .tab-main{
            margin-top: 30px;
            height: 500px;
        }
    }
    .thead {
        background-color: #FAFAFA;
        .tab-th {
            min-width: 120px;
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: rgb(153, 153, 153);
        }
    }
     .tab-th-num{
         min-width: 120px;
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 34px;
     }
    .thead-data{
        background-color: #FAFAFA;
    }
    .photo{
        width: 88px;
        height: 88px;
        border: 4px solid #007EED;
        background: url("../assets/avatar.webp") no-repeat;
        background-size:cover;
        margin-right: 20px;
        border-radius: 50%;

    }
</style>
