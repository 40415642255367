<template>
    <div>
        <headers></headers>
        <left></left>
    </div>
</template>

<script>
import headers from "../components/header";
import left from "../components/left";
export default {
  name: 'Home',
  components: {
        headers,
        left
  },
    data(){
      return{

      }
    }
}
</script>
<style scoped  lang="scss">

</style>
