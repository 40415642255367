<template>
  <div id="app">
    <index/>
    <router-view></router-view>
  </div>
</template>
<script>
  export default {

  }
</script>
<style  lang="less">
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  color: #2c3e50;*/
/*}*/
  *{
    margin: 0;
    padding: 0;
    border: 0;
  }
.el-upload-dragger{
  border: 1px dashed #A0C7FF!important;
  background-color: #F1F1F1!important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 383px!important;
  height: 250px!important;
}
.el-carousel__indicators{
  display: none!important;
}
.el-carousel__arrow,.el-carousel__arrow--left{
  display: none!important;
}
.cursor{
  cursor: pointer;
}
.nowrap{
  white-space: nowrap;
}
  .marLeft11{
    margin-left: 11px;
  }
  .marLeft10{
    margin-left: 10px;
  }
  .marLeft16{
    margin-left: 16px;
  }
  .borBottomC9{
    border-bottom: 1px dashed #C9C9C9;
  }
  .borBottom82C{
    border-bottom: 1px dashed #82C9FF;
  }
  .ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .height37{
    height: 37px;
  }
  .line-height33{
    line-height: 33px;
  }
  .text-right{
    text-align: right;
  }
  .flex-end{
    display: flex;
    align-items: flex-end;
  }
  .dis{
    display: flex;
  }
  .justify-end{
    justify-content: end;
  }
  .justify-between{
    justify-content: space-between;
  }
  .marBottom16{
    margin-bottom: 16px;
  }
  .font14{
    font-size: 14px;
    line-height: 14px;
  }
  .font18{
    font-size: 18px;
    line-height: 18px;
  }
  .color76{
    color: #767676;
  }
  .color5B{
    color: #5B5B5B;
  }
  .color0088FF{
    color: #0088FF;
  }
  .color007E{
    color: #007EED;
  }
  .font20{
    font-size: 20px;
    line-height: 20px;
  }
  .font-bold{
    font-weight: bold;
  }
  .colorFF54{
    color: #FF544F;
  }
  .color61{
    color: #616161;
  }
  .color2CBC{
    color: #2CBCFF;
  }
  .width100{
    width: 100%;
  }
  .color33{
      color: #333333;
  }
  .paBottom100{
    padding-bottom: 100px;
  }
  .colorFF{
    color: #ffffff;
  }
  .disCenter{
    display: flex;
    align-items: center;
  }
  .colorFF00{
    color: #FF0000;
  }
  .height100{
    height: 100%;
  }
  .marLeft28{
    margin-left: 28px;
  }
  .color40{
    color: #404040;
  }
  .color0D9{
    color: #0D96FF;
  }
  .colorFFAA{
    color: #FFAA30;
  }
  .textCenter{
    text-align: center;
  }
  .justify-center{
    justify-content: center;
  }
  .flex-column{
    flex-direction: column;
  }
  .marLeft28{
    margin-left: 28px;
  }
  .flex1{
    flex: 1;
  }
  .flex2{
    flex: 2;
  }
  .color65{
    color: #656565;
  }
  .color69{
    color: #696969;
  }
  .font17{
    font-size: 17px;
  }
  .font18{
    font-size: 18px;
  }
  .font15{
    font-size: 15px;
  }
  .font16{
    font-size: 16px;
    line-height: 16px;
  }
  .color46{
    color: #464646;
  }
  .color78{
    color: #787878;
  }
  div{
    font-family: Microsoft YaHei;
  }
  .bgC2{
    background-color: #c2c2c2;
  }
  .bg0D9{
    background-color: #0D96FF;
  }
  .marTop13{
    margin-top: 13px;
  }
  .marTop14{
    margin-top: 14px;
  }
  .marTop11{
    margin-top: 11px;
  }
  .marTop38 {
    margin-top: 38px;
  }
  .marLeft20{
    margin-left: 20px;
  }
  .marTop36{
    margin-top: 36px;
  }
  .marTop25 {
    margin-top: 25px
  }
  .marTop8{
    margin-top: 8px;
  }
  .marTop46 {
    margin-top: 46px;
  }
  .marTop40{
    margin-top: 30px;
  }
  .justify-center{
    justify-content: center;
  }
  .marTop4{
    margin-top: 4px;
  }
  .paRight13{
    padding-right: 13px;
    box-sizing: border-box;
  }
  .bgFF{
    background-color: #fff;
  }
  .marLeft24{
    margin-left: 24px;
  }
</style>
