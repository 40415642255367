import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import {post,get} from "./lnit/ajax";


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$post = post
Vue.prototype.$get = get
import unit from './lnit/filter'
for(let i in unit){
  Vue.filter(i,unit[i])
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
