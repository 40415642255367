<template>
    <div class="header">
       <div class="header-view">
           <div class="header-left" @click="$router.push('/')">
               <img class="logo" :src="url_prefix + appInfo.pc_logo" alt="">
<!--               <p>{{appInfo.app_name}}</p>-->
           </div>
           <div class="user" >
               <div class="photo" @click="$router.push('/setUser')">
                   <img class="photo" :src="userList.avatar?url_prefix + userList.avatar:'https://cdn.ssjzt.com/images/jzt/avatar/20240530/2024053011144521756.png?imageView2/3/w/50/h/50'" alt="" >
               </div>
               <span class="nickname">
                    {{userList.nickname || "昵称"}}
                </span>
               <div class="set">
                   <div class="disCenter">
                       <img class="set-img" src="../assets/set.png" @click="$router.push('/setUser')">
                       <span class="nowrap colorFF marLeft10">
                         设置
                       </span>
                   </div>
                   <ul class="set-ul">
                       <li
                               @click="setPassFn"
                               :class="setLi == 1?'setLiColor':''"
                       >
                           <p>密码修改</p>
                       </li>
                       <li
                               :class="setLi == 2?'setLiColor':''"
                               @click="quitLoginFn"
                       >
                           <p>退出登录</p>
                       </li>
                       <li></li>
                   </ul>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
    import api from "../lnit/api";

    export default {
        name: "header",
        data(){
            return{
                userList:{},
                url_prefix:null,
                setLi:1,
                appInfo:{},
            }
        },
        created() {
            this.getUserFn()
            this.url_prefix = api.url_prefix
            this.getAppInfoFn()
        },
        methods:{
            setPassFn(){
                this.setLi = 1
                this.$router.push('/setUser')
            },
            //获取网站信息
            getAppInfoFn(){
                this.$get({
                    url:api.app_info
                }).then((res)=>{
                    this.appInfo = res.data.app_info
                })
            },
            quitLoginFn(){
                // this.setLi = 2
                this.$router.replace('/login')
                localStorage.removeItem("token")
            },
            getUserFn(){
                this.$post({
                    url:api.get_user,
                }).then((res)=>{
                    this.userList = res.data.user
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .nickname{
        color: white;
    }
    .header{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 89px;
        margin: 0 auto;
        background-color: #007EED;
        .logo{
            height: 40px;
        }
        .header-view{
            width: 1200px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .header-left{
            display: flex;
            align-items: center;
            cursor: pointer;
            p{
                margin-left: 21px;
                font-size: 28px;
                color: white;
                font-family: Microsoft YaHei;
                font-weight: bold;
            }
        }
        .user{
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            height: 80px;

            .photo{
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 12px;
            }
            .set{
                margin-left: 20px;
                &:hover .set-ul{
                    display: block;
                }
                width: 25px;
                height: 25px;
                .set-img{
                    width: 100%;
                    height: 100%;
                }
                .setLiColor{
                    color: #4C94FF;
                }
                .set-ul{
                    display: none;
                    text-align: center;
                    position: absolute;
                    right: -40px;
                    top: 50px;
                    width: 110px;
                    list-style:none;
                    background-color: #fff;
                    padding: 20px 10px;
                    z-index: 100;
                    li{
                        line-height: 40px;
                    }
                }
            }
        }
    }
    .photo{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: url("../assets/avatar.webp") no-repeat;
        background-size: cover;
    }
</style>
