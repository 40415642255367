<template>
    <div>
        <div class="content-box">
            <div class="box-title flex-end" style="display: flex;justify-content: space-between">
                <div class="flex-end">
                    <img class="title-icon" src="../assets/missin.png">
                    <span class="title">
                         任务
                      </span>
                </div>
                <div class="nav-text">
                    接手限制:【<span class="nav-span">{{day_accept_nums}}</span>】个&nbsp;&nbsp;本日已接:【<span class="nav-span">{{todeyList}}</span>】个&nbsp;&nbsp;剩余:【<span class="nav-span">{{day_accept_nums - todeyList}}</span>】个
                </div>
            </div>
<!--            <div class="box-nav">-->
<!--                <div class="nav-btn">-->
<!--                    <div class="refresh-btn btn" v-for="(item,index) in btnList">-->
<!--                    <span>-->
<!--                        {{item.text}}-->
<!--                    </span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="nav-text">-->
<!--                    接手限制：【200】单 本日已接：【0】单 剩余：【200】单-->
<!--                </div>-->
<!--            </div>-->

<!--            <table class="tableborder" cellspacing="1" cellpadding="3" border="0" width="1109">-->
<!--                <tbody>-->
<!--                <tr>-->
<!--                    <th class="tablerow2"><b>商品图片</b></th>-->
<!--                    <th class="tablerow2"><b>商品标题</b></th>-->
<!--                    <th class="tablerow2"><b>链接</b></th>-->
<!--                    <th class="tablerow2"><b>任务信息</b></th>-->
<!--                    <th class="tablerow2"><b>任务要求</b></th>-->
<!--                    <th class="tablerow2"><b>操作</b></th>-->
<!--                </tr>-->
<!--                    <tr style="text-align:center;border-bottom: 1px solid" v-for="(item,index) in taskList">-->
<!--                        <td class="tablerow1" width="154" height="100" rowspan="1">-->
<!--                            <img :src="url_prefix + item.goods.img" style="max-width:100px;height: 100px" border="0"/>-->
<!--                        </td>-->
<!--                        <td class="tablerow1" style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">-->
<!--                            <p>{{item.goods.title}}</p>-->
<!--                            分类:{{goodsClassList[item.goods.class_id - 1].long_name}}-->
<!--                        </td>-->
<!--                    <td class="tablerow1" height="1" width="150" align="center"><a-->
<!--                            href="https://detail.tmall.com/item.htm?id=623472566007" target="_blank"-->
<!--                            class="uibtn_blue">查看链接</a>-->
<!--                    </td>-->
<!--                    <td class="tablerow1"  style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">-->

<!--                       <p>ID: {{item.id}}</p>-->
<!--                        任务数量:{{item.nums}}-->
<!--                    </td>-->
<!--                    <td class="tablerow1" height="1" width="224" align="center">-->
<!--                        <p-->
<!--                                class=" uibtn_blue"-->
<!--                                @click="dialogUpFn(item)"-->
<!--                        >-->
<!--                            查看亮点和要求-->
<!--                        </p>-->
<!--                    </td>-->
<!--                    <td class="tablerow1" height="" width="145" align="center">-->
<!--                        <el-button class="elbtn" type="primary" @click="takeOverFn(item.id)">立即接手</el-button>-->
<!--                    </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--            </table>-->

            <table class="tableborder" cellspacing="1" cellpadding="3" border="0" width="1109">
                <div>
                    <!--                <tr>-->
                    <!--                    <th class="tablerow2"><b>商品图片</b></th>-->
                    <!--                    <th class="tablerow2"><b>商品标题</b></th>-->
                    <!--                    <th class="tablerow2"><b>链接</b></th>-->
                    <!--                    <th class="tablerow2"><b>任务信息</b></th>-->
                    <!--                    <th class="tablerow2"><b>任务要求</b></th>-->
                    <!--                     <th class="tablerow2"><b>接手时间</b></th>-->
                    <!--                    <th class="tablerow2"><b>状态</b></th>-->

                    <!--                    <th class="tablerow2"><b>操作</b></th>-->
                    <!--                </tr>-->
                    <div class="taskItem" style="text-align:center;background-color:#fff;margin-bottom: 8px" v-for="(item,index) in taskList">
                        <div class="tablerow-photo">
                            <img :src="url_prefix + item.goods.img" border="0" alt="商品图"/>
                        </div>
                        <div class="itemContent flex1 marLeft11 dis flex-column">
                            <div class="disCenter justify-between  pabottom9 borBottomC9">
                                <div class="disCenter">
                                    <div class="target">
                                        {{goodsClassList[item.goods.class_id - 1].long_name}}
                                    </div>
                                    <span class="color65 font18">
                                    产品名称：{{item.goods.title}}
                                </span>
                                </div>
                            </div>
                            <div class="dis justify-between flex-column flex1 marTop4">
                                <div class="disCenter">
                            <span class="font15 color78">
                                产品ID：{{item.id}}
                            </span>
                                </div>
                                <div class="disCenter font15 color78">
                                    任务数量: {{item.nums}}
                                </div>
                                <div class="disCenter justify-between marTop4">
                                    <div class="disCenter flex1 justify-between">
<!--                                        <p class="font15 color78">-->
<!--                                            接手时间：{{item.pk_js_time | dateFormat}}-->
<!--                                        </p>-->
                                       <div class="disCenter flex1 ">
                                           <div class="disCenter detail">
                                               <img src="../assets/mission/detail.png" alt="">
                                               <a :href="item.goods.link" target="_blank" class="uibtn_blue">查看链接</a>
                                           </div>
                                           <div class="disCenter spot"  @click="dialogUpFn(item)">
                                               <img src="../assets/mission/spot.png" alt="">
                                               <span>
                                                    要求亮点
                                                </span>
                                           </div>
                                       </div>
                                        <div class="upload disCenter justify-center" @click="takeOverFn(item.id)">
                                            立即接手
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--                    <div class="tablerow1" style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">-->
                        <!--                        <p>{{item.goods.title}}</p>-->
                        <!--                        分类:{{goodsClassList[item.goods.class_id - 1].long_name}}-->
                        <!--                    </div>-->
                        <!--                    <div class="tablerow1" height="1" width="150" align="center"><a-->
                        <!--                            :href="item.goods.link" target="_blank"-->
                        <!--                            class="uibtn_blue">查看链接</a>-->
                        <!--                    </div>-->

                        <!--                    <div class="tablerow1"  style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">-->

                        <!--                        <p>ID: {{item.id}}</p>-->
                        <!--                        任务数量:{{item.nums}}-->
                        <!--                    </div>-->
                        <!--                    <div class="tablerow1" height="1" width="224" align="center">-->
                        <!--                        <p-->
                        <!--                           class="open-box uibtn_blue"-->
                        <!--                           @click="dialogUpFn(item)"-->
                        <!--                           >-->
                        <!--                            查看亮点和要求-->
                        <!--                        </p>-->
                        <!--                    </div>-->
                        <!--                     <div class="tablerow1" height="1" width="224" align="center">-->
                        <!--                        <p> {{item.pk_js_time | dateFormat}}</p>-->
                        <!--                    </div>-->
                        <!--                    <div class="tablerow1" style="color:rgb(144, 147, 153)" height="" width="145" align="center">-->
                        <!--                       <p v-if="item.status == 1">未发货</p>-->
                        <!--                        <p v-if="item.status == 2">待上传</p>-->
                        <!--                        <p v-if="item.status == 3">待审核</p>-->
                        <!--                        <p v-if="item.status == 4">已分发</p>-->
                        <!--                        <p v-if="item.status == 5">已完成</p>-->
                        <!--                    </div>-->

                    </div>

                    <div v-if="!taskList.length" style="width: 100%;display: flex;align-items: center;flex-direction: column;height: 200px;justify-content: center;background-color: #fff;" >
                        <img src="../assets/empty.png" style="width: 60px;" alt="">
                        <p style="color:rgba(0,0,0,0.6);font-weight: bold;margin-top: 10px">暂无任务</p>
                    </div>
                </div>
            </table>
             <el-pagination
                style="text-align:center;"
                background
                layout="prev, pager, next"
                :total="videoTotal"
                @current-change="sizeChange"
                >
            </el-pagination>
        </div>
<!--        <el-dialog-->
<!--                title="提示"-->
<!--                :visible.sync="centerDialogVisible"-->
<!--                width="30%"-->
<!--                center>-->
<!--            <p class="btn">宝贝亮点</p><div style="padding:10px;font-size:14px;text-align:center;">{{liangdian}}</div>-->
<!--            <p class="btn">宝贝要求</p><div style="padding:10px;font-size:14px;text-align:center;">{{pk_yaoqiu}}</div>-->
<!--        </el-dialog>-->
        <div class="popup" @mousewheel="mousewheel" v-if="centerDialogVisible">
            <div class="view">
                <div class="popup-view">
                    <div class="fixed_title">提示</div>
                    <div class="yq">
                        <div class="yqTitle">
                            宝贝要求
                        </div>
                        <div class="yqInput">
                            {{pk_yaoqiu}}
                        </div>
                    </div>
                    <div class="ld">
                        <div class="ldTitle">
                            宝贝亮点
                        </div>
                        <div class="ldInput">
                            {{liangdian}}
                        </div>
                    </div>
                </div>
                <img class="close" @click="centerDialogVisible = false" src="../assets/close.png" alt="">
            </div>

        </div>
    </div>
</template>

<script>
    import api from "../lnit/api"
    export default {
        name: "saloon",
        data(){
            return{
                page:1,
                taskList:[],//大厅列表
                userInfo:{},
                btnList:[
                    {
                        text:'全部任务'
                    },
                    {
                        text:'已接手'
                    },
                    {
                        text:'待上传'
                    },
                    {
                        text:'待通过'
                    },
                    {
                        text:'仲裁中'
                    },
                    {
                        text:'已完成'
                    }
                ],
                url_prefix:null,
                goodsClassList:null,//商品分类
                centerDialogVisible:false,
                pk_yaoqiu:null,//要求
                liangdian:null,//亮点
                videoTotal:null,
                day_accept_nums:0,
                todeyList:0,
            }
        },
        created() {
            this.taskListFn()
            this.url_prefix = api.url_prefix
        },
        methods:{
            mousewheel(e){
                e.preventDefault();
            },
            dialogUpFn(item){//亮点和要求
                this.centerDialogVisible = true
                this.pk_yaoqiu = item.pk_yaoqiu
                this.liangdian = item.liangdian
            },
            takeOverFn(id){
                let that = this
                this.$alert( this.userInfo.one_video_price<=0?'管理员还未设置视频佣金,确定继续领取任务?':'确认领取任务?', '温馨提示', {
                    confirmButtonText: '领取',
                    showCancelButton:true,
                    callback: action => {
                        if(action == 'confirm'){
                            that.$post({
                                url:api.accept_task,
                                data:{
                                    id:id
                                }
                            }).then((res)=>{
                                if(res.data.code == 1){
                                    that.$message({
                                        message:res.data.msg.msg,
                                        type:'error'
                                    })
                                }else if(res.data.code === 0){
                                    that.$message({
                                        message:'接手成功',
                                        type:'success'
                                    })
                                    that.taskListFn()
                                    // this.$router.push('/mission')
                                }
                            })
                        }
                    }
                });
            },
            sizeChange(e){
                this.page = e
                this.taskListFn()
            },
            taskListFn(){
                this.$get({
                    url:api.get_goods_class,
                }).then((res)=>{
                    this.goodsClassList = res.data.list
                })
                this.$get({
                    url:api.pk_task_list,
                    data:{
                        page:this.page,
                    }
                }).then((res)=>{
                   this.taskList = res.data.list.data
                   this.videoTotal = res.data.list.total
                })
                this.$get({
                    url:api.get_user
                }).then((res)=>{
                    this.day_accept_nums = res.data.user.day_accept_nums
                    this.userInfo = res.data.user
                })
                this.$get({
                    url:api.today_accept
                }).then((res)=>{
                    this.todeyList = res.data.nums
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    a{
        text-decoration: none;
    }
    .nav-span{
        font-weight: 400;
    }
    .tablerow-photo{
        width: 119px;
        height: 119px;
        img{
            width: 119px;
            height: 119px;
            border-radius: 20px;
        }
    }
    .taskItem{
        display: flex;
        padding: 22px 14px 21px 7px;
        box-sizing: border-box;
    }
    .popup{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        .close{
            width: 46px;
            height: 46px;
            margin-top: 31px;
        }
        .view{
            width: 460px;
            display: flex;
            align-items: center;
            flex-direction: column;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

        }
        .popup-view{
            width: 460px;
            height: 502px;
            background: url("../assets/popup/popup-bg.png") no-repeat;
            background-size: 460px 502px;
            .fixed_title{
                font-size: 24px;
                color: white;
                font-weight: bold;
                position: absolute;
                left: 50%;
                top: 15px;
                transform: translateX(-50%);
            }
            .ld{
                position: relative;
                width: 356px;
                margin: 58px auto 0;
                .ldTitle{
                    position: absolute;
                    left: 0;
                    top: -37px;
                    width: 91px;
                    height: 37px;
                    background: #FFA21B;
                    border-radius: 15px 15px 0px 0px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                }
                .ldInput{
                    width: 100%;
                    height: 95px;
                    background: #F4F4F4;
                    border: 1px solid #DFDFDF;
                    border-radius: 0px 15px 15px 15px;
                    padding: 10px;
                    box-sizing: border-box;
                    color: #444444;
                }
            }
            .yq{
                position: relative;
                width: 356px;
                margin: 118px auto 0;
                .yqTitle{
                    position: absolute;
                    left: 0;
                    top: -37px;
                    width: 91px;
                    height: 37px;
                    background: #178FFF;
                    border-radius: 15px 15px 0px 0px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                }
                .yqInput{
                    width: 100%;
                    height: 95px;
                    background: #F4F4F4;
                    border: 1px solid #DFDFDF;
                    border-radius: 0px 15px 15px 15px;
                    padding: 10px;
                    box-sizing: border-box;
                }
            }
        }
    }
    .target{
        padding: 2px 9px;
        background-color: #3EB9FF;
        border-radius: 9px;
        color: white;
        margin-right: 4px;
    }
    .detail{
        padding: 4px 13px;
        border: 2px solid #3FC6F8;
        border-radius: 17px;
        color: #3FC6F8;
        img{
            width: 19px;
            height: 20px;
            margin-right: 5px;
        }
    }
    .spot{
        padding: 2px 13px;
        border: 2px solid #FFAA30;
        border-radius: 17px;
        color: #FFAA30;
        margin-left: 12px;
        box-sizing: border-box;
        cursor: pointer;
        img{
            width: 19px;
            height: 25px;
            margin-right: 5px;
        }
    }
    .nav-text{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #6B6B6B;
    }
    .upload{
        padding: 9px 21px;
        background-color: #FF544F;
        color: white;
        font-size: 14px;
        line-height: 14px;
        border-radius: 17px;
        margin-left: 13px;
        cursor: pointer;
    }
    .lookKd{
        padding: 9px 21px;
        background-color: #3EB9FF;
        color: white;
        font-size: 14px;
        margin-left: 13px;
        line-height: 14px;
    }
        .pabottom9{
        padding-bottom: 9px;
    }
    .bgFFAA{
        background-color: #FFAA30;
    }
    .bgBA{
        background-color: #BABABA;
    }
    .content-box {
        width: 100%;

        .box-title {
            text-align: left;
            font-size: 13px;
            margin-bottom: 7px;
            background-color: #fff;
            padding: 11px 10px 13px 11px;

        }

        .box-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;

            .nav-btn {
                display: flex;
                align-items: center;
                .btn {
                    position: relative;
                    color: #fff;
                    background: linear-gradient(90deg, #6245D3, #E524D6);
                    border: 0px;
                    padding: 4px 8px;
                    margin-right: 10px;
                    border-radius: 3px;
                    word-break: keep-all;
                    white-space: nowrap;
                }
            }
        }
    }
    .title{
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 22px;
        color: #FFAA30;
    }
    .title-icon{
        width: 24px;
        height: 28px;
        margin-right: 4px;
    }

    .tableborder {
        border-radius: 2px;
        width: 100%;
        magin-right: 20px;
        font-size: 13px;
        margin-bottom: 7px;
    }

    .tableborder th, .minwidth {
        word-break: keep-all;
        white-space: nowrap;
        color: rgb(144, 147, 153);
        text-align: center;
        font-style: normal;
        font-weight: normal;
    }

    .uibtn_blue {
        color: #3FC6F8;
        font-size: 12px;
        word-break: keep-all;
        white-space: nowrap;
        text-decoration: none;
    }
    .btn {
        position: relative;
        color: #fff;
        /*background: #8C45D3;*/
        background:#0099FF;
        text-align:center;
        border: 0px;
        padding: 4px 8px;
        margin-left: 10px;
        border-radius: 3px;
        word-break: keep-all;
        white-space: nowrap;
        transition-duration: .8s;
    }
    .elbtn{
        font-size: 12px;
        padding: 5px 10px;
    }
    .tablerow2{
        text-align: center;
    }
</style>
