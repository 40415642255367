const api = {
    pname:'jzt',
    url_prefix:'https://cdn.ssjzt.com/',//图片前缀
    url:'https://api.ssjzt.com',
    video_src:'company.mp4',
    // url:'http://test.zxw233.com',//测试域名
    app_info:'/index/app_info',//app信息
    upload_file:'/api/common/upload_file',//上传视频
    pk_re_up_task:'/api/task/pk_re_up_task',
    avatar:'api/user/avatar',//上传视频
    login:'/api/login/login',//登录
    save_videos:'/api/task/save_videos ',//保存视频
    pk_task_list:'/api/task/pk_task_list',//大厅列表
    get_goods_class:'/api/goods/get_goods_class',//商品类型
    accept_task:'/api/task/accept_task',//接收任务
    pk_my_task_list:'/api/task/pk_my_task_list',//已接任务
    get_user:'/api/user/get_user',//获取用户信息
    update_user_info:'/api/user/update_user_info',//修改昵称
    bind_alipay:'/api/user/bind_alipay',//绑定支付宝
    bind_express:"/api/user/bind_express",//绑定收货地址
    up_avatar:'/api/user/up_avatar',//头像修改
    pk_task_details:'/api/task/pk_task_details',//任务详情
    pk_re_save_videos:'/api/task/pk_re_save_videos',//重新上传
    tx_record:'/api/common/tx_record',//提现明细
    today_accept:'/api/task/today_accept',//当天已接个数
    update_pwd:'/api/user/update_pwd',//修改密码
    money_details:'/api/common/money_details',//明细
    do_tx:'/api/common/do_tx',//申请提现
    contact:'/index/contact',//联系方式
    pc_data:'/index/pc_data',//平台数据
    bind_wx:'/api/user/bind_wx',//微信绑定
}

export default api
